import React from "react";
import bannerrightimg from "../../images/banner-img-right.svg";

const Banner = () => {
  return (
    <>
      <section
        id='banner'
        className='md:h-[90vh] max-md:pt-8 md:px-16 items-center relative flex overflow-hidden max-md:justify-center py-8'
      >
        <h1 className='uppercase text-gray-200 md:text-[100px] md:text-6xl text-2xl top-[22rem] max-md:-right-[13rem] absolute font-black rotate-90 -right-[18rem] -z-10'>
          UI UX designer
        </h1>
        <div className='container h-100 md:mx-auto items-center flex max-md:flex-col-reverse max-md:gap-8'>
          <div className='h-100 flex flex-col md:w-1/2 max-md:p-[32px]'>
            <h1 className='md:text-8xl text-6xl text-black font-medium md:text-left max-w-[600px]'>
              Hello! I am Altmesh
              <span className='text-red-600'>,</span>
            </h1>
            <p className='md:text-left text-2xl mt-4 text-gray-600'>
              a passionate UI/UX designer with a vision to transform ideas into visually stunning
              and user-friendly experiences. I specialize in creating captivating digital interfaces
              that leave a lasting impression.
            </p>
          </div>
          <div className='md:w-1/2 w-full flex justify-center md:hover:translate-x-5 transition ease-in-out'>
            <img
              className='max-md:max-h-[18rem] max-h-[28rem] md:hover:translate-x-5 transition ease-in-out'
              src={bannerrightimg}
              alt=''
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
