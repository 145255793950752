import React from "react";
import logo from "../images/Logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <section id='footer'>
      <div className='container max-md:justify-center mx-auto flex items-center py-8 justify-between max-md:flex-col max-md:gap-8'>
        <div className='branding mx-auto flex items-center gap-4 w-full md:w-1/2 justify-center'>
          <img
            className='max-w-[4rem] max-md:max-w-[3.5rem]'
            src={logo}
            alt=''
          />
          <div className='footer-title flex text-left flex-col'>
            <h3 className='text-4xl max-md:text-2xl'>Altmesh Khan</h3>
            <p className='text-xl text-gray-600'>UI UX Designer</p>
          </div>
        </div>
        <div className='contact-info md:w-1/2 md:gap-16 gap-4 flex max-md:flex-col max-md:items-center md:justify-end'>
          <a
            href='mailto:khanaltmesh@gmail.com'
            className='flex gap-4 ease-in-out hover:scale-105 md:pr-8 md:justify-end'
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              size='xl'
            />
            <p>khanatlmesh@gmail.com</p>
          </a>
          <a
            href='mailto:khanaltmesh@gmail.com'
            className='flex gap-4 ease-in-out hover:scale-105 md:pl-8 border-l-2'
          >
            <FontAwesomeIcon
              icon={faPhone}
              size='xl'
            />
            <p>+91-966-976-6616</p>
          </a>
        </div>
      </div>
      <div className='py-4 max-md:px-[4rem] bg-black'>
        <p className='text-white'>Copyright © 2023 Altmesh Khan. All rights reserved.</p>
      </div>
    </section>
  );
};

export default Footer;
