import React from "react";
import logo from "../images/Logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faBehance, faFigma, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  return (
    <>
      <div className='navbar max-md:px-4'>
        <div className='container justify-between max-md:flex-col max-md:gap-8 mx-auto py-8 items-center flex'>
          <div className='items-center flex gap-2'>
            <img
              className='md:max-w-[3rem]  max-w-[2rem] md:hover:scale-110 ease-in-out cursor-pointer md:hover:rotate-45 transition'
              src={logo}
              alt=''
            />
          </div>
          <div className='header-social-icon gap-8 flex items-center'>
            <a
              href='https://www.instagram.com/insta_altmesh/'
              target='_blank'
              rel='noreferrer'
              className='hover:scale-110 transition ease-in-out'
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size='2xl'
                color='black'
              />
            </a>
            <a
              href='https://www.behance.net/Altmesh'
              target='_blank'
              rel='noreferrer'
              className='hover:scale-110 transition ease-in-out'
            >
              <FontAwesomeIcon
                icon={faBehance}
                size='2xl'
                color='black'
              />
            </a>
            <a
              href='https://www.figma.com/@altmesh'
              target='_blank'
              rel='noreferrer'
              className='md:hover:scale-110 transition ease-in-out'
            >
              <FontAwesomeIcon
                icon={faFigma}
                size='xl'
                color='black'
              />
            </a>
            <a
              href='https://www.linkedin.com/in/altmesh/'
              target='_blank'
              rel='noreferrer'
              className='md:hover:scale-110 transition ease-in-out'
            >
              <FontAwesomeIcon
                icon={faLinkedinIn}
                size='2xl'
                color='black'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
